body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slds-brand-band {
  margin-top: 60px;
}

/* Adjust margin for screens 768px and up */
@media screen and (min-width: 768px) {
  .slds-brand-band {
    margin-top: 100px;
  }
}

.kipzco-brand-mobile {
  font-size: 19px;
  width: 100%;
  text-align: center;
  margin-left: -35px;
}

.kipzco-brand-desktop {
  font-size: 26px;
  padding: 4.5px 0 0 .5rem;
  color: rgb(68, 68, 68);
}

.slds-spinner--brand .slds-spinner__dot-a:after,
.slds-spinner--brand .slds-spinner__dot-a:before,
.slds-spinner--brand .slds-spinner__dot-b:after,
.slds-spinner--brand .slds-spinner__dot-b:before,
.slds-spinner--brand.slds-spinner:after,
.slds-spinner--brand.slds-spinner:before,
.slds-spinner_brand .slds-spinner__dot-a:after,
.slds-spinner_brand .slds-spinner__dot-a:before,
.slds-spinner_brand .slds-spinner__dot-b:after,
.slds-spinner_brand .slds-spinner__dot-b:before,
.slds-spinner_brand.slds-spinner:after,
.slds-spinner_brand.slds-spinner:before {
  background-color: #123273;
}

.spinner-text {
  position: absolute;
  top: 55%;
  text-align: center;
  width: 100%;
  font-size: 1.2em;
  font-weight: 500;
}

.metrics {
  margin-top: 8px;
}

.metrics li.slds-item {
  background-color: #ffffff;
  box-shadow: var(--slds-c-card-shadow, var(--sds-c-card-shadow, 0 2px 2px 0 rgba(0, 0, 0, .1)));
}

@media screen and (max-width: 768px) {

  tr.datatable-row td,
  tr.datatable-row th,
  .slds-max-medium-table_stacked tr.datatable-row>td:last-child {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .slds-max-medium-table_stacked tr.datatable-row>td:before,
  .slds-max-medium-table_stacked tr.datatable-row>th:before {
    display: inline-block;
    width: 7rem;

  }

  .datatable-value {
    display: contents;
    margin-left: 10px;
  }
}

.slds-card+.slds-card {
  margin-top: 0.5rem;
}